import React from "react";
// import emailjs from "@emailjs/browser";
// import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { Tooltip } from "./Tooltip";

const Contact = ({ classicHeader, darkTheme, dataAbout }) => {
  // const form = useRef();
  // const [sendingMail, setSendingMail] = useState(false);

  /*
  const sendEmail = (e) => {
    e.preventDefault();
    setSendingMail(true);
    emailjs
      .sendForm(
        "service_i86k3ms",
        "template_si6cin9",
        form.current,
        "c9HsDgGF0tvWyVnAL"
      )
      .then(
        (result) => {
          document.getElementById("contact-form").reset();
          toast.success("Message sent successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: darkTheme ? "dark" : "light",
          });
          console.log(result.text);
          setSendingMail(false);
        },
        (error) => {
          toast.error("Something went wrong!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: darkTheme ? "dark" : "light",
          });
          console.log(error.text);
          setSendingMail(false);
        }
      );
  };*/

  return (
    <section
      id="contact"
      className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Contact
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Get in Touch
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gy-5">
          {/* contact details */}
          <div className="col-md-4 col-xl-3 order-1 order-md-0 text-center text-md-start">
            <h2
              className={
                "mb-3 text-5 text-uppercase " + (darkTheme ? "text-white" : "")
              }
            >
              Address
            </h2>
            <p className={"text-3 mb-4 " + (darkTheme ? "text-light" : "")}>
              {dataAbout.address}
            </p>
            <p className={"text-3 mb-1 " + (darkTheme ? "text-light" : "")}>
              <span className="text-primary text-4 me-2">
                <i className="fa-brands fa-whatsapp" />
              </span>
              <a href="https://wa.link/d9zofo" rel="noopener" target="_blank" >
                {dataAbout.phone}</a>
            </p>

            <p className={"text-3 mb-4 " + (darkTheme ? "text-light" : "")}>
              <span className="text-primary text-4 me-2">
                <i className="fas fa-envelope" />
              </span>
              {dataAbout.email}
            </p>
            <h2
              className={
                "mb-3 text-5 text-uppercase " + (darkTheme ? "text-white" : "")
              }
            >
              Follow Me
            </h2>
            <ul
              className={
                "social-icons justify-content-center justify-content-md-start " +
                (darkTheme ? "social-icons-muted" : "")
              }
            >
              {/*<li className="social-icons-dribbble">
                <Tooltip text="Dribbble" placement="top">
                  <a
                    href="http://www.dribbble.com/harnishdesign/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-dribbble" />
                  </a>
                </Tooltip>
            </li>*/}
              {dataAbout.twitter && (
                <li className="social-icons-twitter">
                  <Tooltip text="Twitter" placement="top">
                    <a
                      href={dataAbout.twitter}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-twitter" />
                    </a>
                  </Tooltip>
                </li>
              )}
              {dataAbout.facebook && (
                <li className="social-icons-facebook">
                  <Tooltip text="Facebook" placement="top">
                    <a
                      href={dataAbout.facebook}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-facebook-f" />
                    </a>
                  </Tooltip>
                </li>)}

              {dataAbout.linkedin && (
                <li className="social-icons-linkedin">
                  <Tooltip text="LinkedIn" placement="top">
                    <a
                      href={dataAbout.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </Tooltip>
                </li>)}

              {dataAbout.google && (
                <li className="social-icons-google">
                  <Tooltip text="Google" placement="top">
                    <a
                      href={dataAbout.google}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-google" />
                    </a>
                  </Tooltip>
                </li>
              )}
              {dataAbout.github && (
                <li className="social-icons-github">
                  <Tooltip text="Github" placement="top">
                    <a
                      href={dataAbout.github}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-github" />
                    </a>
                  </Tooltip>
                </li>)}
              
                <li className="social-icons-upword">
                <Tooltip text="upwork" placement="top">
                  <a
                    href="https://www.upwork.com/freelancers/~01d765f973c0203f2a"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                  <img
                    className="img-fluid rounded-pill d-block"
                    src="https://cdn.prod.website-files.com/603fea6471d9d8559d077603/60d9ec8c144b57376ef2fd12_Group%202121.svg"
                    title="Upwork"
                    alt="upwork"
                      />
                  </a>
                </Tooltip>
              </li>

              <li className="social-icons-fiverr">
                <Tooltip text="fiverr" placement="top">
                  <a
                    href="https://www.fiverr.com/bijonkrishna?public_mode=true"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                  <img
                    className="img-fluid rounded-pill d-block"
                    src="data:image/x-icon;base64,AAABAAEAEBAAAAEAIABoBAAAFgAAACgAAAAQAAAAIAAAAAEAIAAAAAAAAAQAABMLAAATCwAAAAAAAAAAAAAAAAAAAAAAAHO/HQBzvx0Hc78dSXO/Hahzvx3kc78d+3O/Hftzvx3kc78dqHO/HUhzvx0Hc78dAAAAAAAAAAAAc78fAHO/HQBzvx0Yc78dknO/He9zvx3/c78d/3O/Hf9zvx3/c78d/3O/Hf9zvx3uc78dknO/HRhzvx0Ac78cAHO/HQBzvx0Yc78dsXO/Hf9zvx3/cr4b/3G+Gf9yvxv/c78d/3K+G/9xvhn/cr4b/3O/Hf9zvx2xc78dGHO/HQBzvx0Gc78dknO/Hf9zvx3/c78e/43LR/+c0l//hMc5/3K/G/+JyUH/nNJf/4jJP/9zvxz/c78d/3O/HZJzvx0Gc78dSXO/He1zvx3/c78d/3TAH//O6K///////67afP9wvRf/v+KY//////+74JL/cb4a/3O/Hf9zvx3tc78dSXO/Hahzvx3/c78d/3O/Hf90wB//0Omz//////+v237/b70X/8Hjm///////veGV/3G+Gv9zvx3/c78d/3O/Hadzvx3jc78d/3O/Hf9zvx3/dMAf/9Dps///////r9t+/2+9F//B45v//////73hlf9xvhr/c78d/3O/Hf9zvx3jc78d+3O/Hf9zvx3/c78d/3S/Hv/Q6bP//////6/afv9vvRb/weOb//////+94ZX/cb4a/3O/Hf9zvx3/c78d+3O/Hftzvx3/c78c/3nCJv+23on/7Pbh///////e8Mn/w+Se/+Xz1f//////veGV/3G+Gv9zvx3/c78d/3O/Hftzvx3jc78d/3K/HP98wyz/4PHN/////v//////+/35//n89f/4/PP/+fz1/7nfjv9xvhv/c78d/3O/Hf9zvx3jc78dp3O/Hf9zvx3/dcAg/4vKQ//Z7sL//////8fmpf+SzVD/kc1N/5DMS/+CxjX/c78c/3O/Hf9zvx3/c78dqHO/HUpzvx3tc78d/3O/Hf9xvhn/uN+N///////x+en/2+/F/5/TZP9vvRf/cr8b/3O/Hf9zvx3/c78d7XO/HUlzvx0Gc78dknO/Hf9zvx3/cr8c/4DFMv/E5J//5/TZ/+334v+o13P/cb4a/3O/Hf9zvx3/c78d/3O/HZJzvx0Gc78dAHO/HRhzvx2xc78d/3O/Hf9yvxz/dMAf/37ELv+AxTL/ecIm/3O/Hf9zvx3/c78d/3O/HbFzvx0Yc78dAHS/GgBzvx0Ac78dGHO/HZJzvx3uc78d/3O/Hf9yvxz/cr8c/3O/HP9zvx3/c78d73O/HZJzvx0Yc78dAHS/GgAAAAAAAAAAAHO/HQBzvx0Hc78dSXO/Hahzvx3kc78d+3O/Hftzvx3kc78dqHO/HUlzvx0Hc78dAAAAAAAAAAAA4AcAAMADAACAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAIABAADAAwAA4AcAAA=="
                    title="fiverr"
                    alt="fiverr"
                      />
                  </a>
                </Tooltip>
              </li>
              <li className="social-icons-freelancer">
              <Tooltip text="freelancer" placement="top">
                <a
                  href="https://www.freelancer.com/u/bbijon"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                <img
                  className="img-fluid rounded-pill d-block"
                  src="https://www.freelancer.com/favicon.ico"
                  title="freelancer"
                  alt="freelancer"
                    />
                </a>
              </Tooltip>
            </li>
              
            </ul>
          </div>
          {/* contact form */}
        </div>
      </div>
    </section>
  );
};

export default Contact;
