import React, { useState } from "react";
import { Tooltip } from "./Tooltip";
import { Link } from "react-scroll";

const Header = ({ classicHeader, darkTheme, homeRef, handleNavClick, dataAbout }) => {
  const [isNavModalClose, setIsNavModalClose] = useState(true);
  var fullname = dataAbout.firstName + " " + dataAbout.middleName + " " + dataAbout.lastName
  // console.log("dataAbout>>>>>>>>>>>>>>>>>", dataAbout)
  return (
    <header id="header" className="sticky-top">
      {/* Navbar */}
      <nav className="primary-menu navbar navbar-expand-lg navbar-dark bg-dark border-bottom-0">
        <div className="container-fluid position-relative h-100 flex-lg-column ps-3 px-lg-3 pt-lg-3 pb-lg-2">
          {/* Logo */}
          <Link
            smooth
            duration={500}
            style={{ cursor: "pointer" }}
            to="home"
            className="mb-lg-auto mt-lg-4"
            onClick={(e) => {
              e.preventDefault();
              setIsNavModalClose(true);
            }}
          >
            <span className="bg-dark-2 rounded-pill p-2 mb-lg-1 d-none d-lg-inline-block">
              <img
                className="img-fluid rounded-pill d-block"
                src={dataAbout.profileImage}
                title="I'm Simone"
                alt="profile"
              />
            </span>
            <h1 className="text-5 text-white text-center mb-0 d-lg-block">
              {fullname}
            </h1>
          </Link>
          {/* Logo End */}
          <div
            id="header-nav"
            className={
              isNavModalClose
                ? "collapse navbar-collapse w-100 my-lg-auto "
                : "show navbar-collapse w-100 my-lg-auto"
            }
          >
            <ul className="navbar-nav text-lg-center my-lg-auto py-lg-3">
              <li className="nav-item">
                <Link
                  target={homeRef}
                  className="nav-link "
                  smooth
                  duration={500}
                  style={{ cursor: "pointer" }}
                  activeClass="active"
                  spy
                  to="home"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsNavModalClose(true);
                  }}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link "
                  smooth
                  duration={500}
                  style={{ cursor: "pointer" }}
                  activeClass="active"
                  spy
                  to="about"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsNavModalClose(true);
                  }}
                >
                  About Me
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link "
                  smooth
                  duration={500}
                  style={{ cursor: "pointer" }}
                  activeClass="active"
                  spy
                  to="services"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsNavModalClose(true);
                  }}
                >
                  What I Do
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link "
                  smooth
                  duration={500}
                  style={{ cursor: "pointer" }}
                  activeClass="active"
                  spy
                  to="resume"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsNavModalClose(true);
                  }}
                >
                  Resume
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link "
                  smooth
                  duration={500}
                  style={{ cursor: "pointer" }}
                  activeClass="active"
                  spy
                  to="portfolio"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsNavModalClose(true);
                  }}
                >
                  Portfolio
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link "
                  smooth
                  duration={500}
                  style={{ cursor: "pointer" }}
                  activeClass="active"
                  spy
                  to="testimonial"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsNavModalClose(true);
                  }}
                >
                  Testimonial
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link "
                  smooth
                  duration={500}
                  style={{ cursor: "pointer" }}
                  activeClass="active"
                  spy
                  to="contact"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsNavModalClose(true);
                  }}
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          <ul className="social-icons social-icons-muted social-icons-sm mt-lg-auto ms-auto ms-lg-0 d-flex">
            {dataAbout.facebook && (
              <li className="social-icons-facebook">
                <Tooltip text="Facebook" placement="top">
                  <a
                    href={dataAbout.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-facebook-f" />
                  </a>
                </Tooltip>
              </li>
            )}
            {dataAbout.linkedin && (
              <li className="social-icons-linkedin">
                <Tooltip text="LinkedIn" placement="top">
                  <a
                    href={dataAbout.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-linkedin-in" />
                  </a>
                </Tooltip>
              </li>
            )}
            {dataAbout.twitter && (
              <li className="social-icons-twitter">
                <Tooltip text="Twitter" placement="top">
                  <a
                    href={dataAbout.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-twitter" />
                  </a>
                </Tooltip>
              </li>
            )}
            {dataAbout.dribbble && (
              <li className="social-icons-dribbble">
                <Tooltip text="Dribbble" placement="top">
                  <a
                    href={dataAbout.dribbble}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-dribbble" />
                  </a>
                </Tooltip>
              </li>
            )}
            {dataAbout.github && (
              <li className="social-icons-github">
                <Tooltip text="Google" placement="top">
                  <a
                    href={dataAbout.github}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-github" />
                  </a>
                </Tooltip>
              </li>
            )}

            <li className="social-icons-upwork">
                <Tooltip text="Google" placement="top">
                  <a
                    href="https://www.upwork.com/freelancers/~01d765f973c0203f2a"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                <img
                className="img-fluid rounded-pill d-block"
                src="https://cdn.prod.website-files.com/603fea6471d9d8559d077603/60d9ec8c144b57376ef2fd12_Group%202121.svg"
                title="Upwork"
                alt="upwork"
                  />
                  </a>
                </Tooltip>
            </li>

            <li className="social-icons-fiverr">
            <Tooltip text="fiverr" placement="top">
              <a
                href="https://www.fiverr.com/bijonkrishna?public_mode=true"
                target="_blank"
                rel="noopener noreferrer"
              >
              <img
                className="img-fluid rounded-pill d-block"
                src="data:image/x-icon;base64,AAABAAEAEBAAAAEAIABoBAAAFgAAACgAAAAQAAAAIAAAAAEAIAAAAAAAAAQAABMLAAATCwAAAAAAAAAAAAAAAAAAAAAAAHO/HQBzvx0Hc78dSXO/Hahzvx3kc78d+3O/Hftzvx3kc78dqHO/HUhzvx0Hc78dAAAAAAAAAAAAc78fAHO/HQBzvx0Yc78dknO/He9zvx3/c78d/3O/Hf9zvx3/c78d/3O/Hf9zvx3uc78dknO/HRhzvx0Ac78cAHO/HQBzvx0Yc78dsXO/Hf9zvx3/cr4b/3G+Gf9yvxv/c78d/3K+G/9xvhn/cr4b/3O/Hf9zvx2xc78dGHO/HQBzvx0Gc78dknO/Hf9zvx3/c78e/43LR/+c0l//hMc5/3K/G/+JyUH/nNJf/4jJP/9zvxz/c78d/3O/HZJzvx0Gc78dSXO/He1zvx3/c78d/3TAH//O6K///////67afP9wvRf/v+KY//////+74JL/cb4a/3O/Hf9zvx3tc78dSXO/Hahzvx3/c78d/3O/Hf90wB//0Omz//////+v237/b70X/8Hjm///////veGV/3G+Gv9zvx3/c78d/3O/Hadzvx3jc78d/3O/Hf9zvx3/dMAf/9Dps///////r9t+/2+9F//B45v//////73hlf9xvhr/c78d/3O/Hf9zvx3jc78d+3O/Hf9zvx3/c78d/3S/Hv/Q6bP//////6/afv9vvRb/weOb//////+94ZX/cb4a/3O/Hf9zvx3/c78d+3O/Hftzvx3/c78c/3nCJv+23on/7Pbh///////e8Mn/w+Se/+Xz1f//////veGV/3G+Gv9zvx3/c78d/3O/Hftzvx3jc78d/3K/HP98wyz/4PHN/////v//////+/35//n89f/4/PP/+fz1/7nfjv9xvhv/c78d/3O/Hf9zvx3jc78dp3O/Hf9zvx3/dcAg/4vKQ//Z7sL//////8fmpf+SzVD/kc1N/5DMS/+CxjX/c78c/3O/Hf9zvx3/c78dqHO/HUpzvx3tc78d/3O/Hf9xvhn/uN+N///////x+en/2+/F/5/TZP9vvRf/cr8b/3O/Hf9zvx3/c78d7XO/HUlzvx0Gc78dknO/Hf9zvx3/cr8c/4DFMv/E5J//5/TZ/+334v+o13P/cb4a/3O/Hf9zvx3/c78d/3O/HZJzvx0Gc78dAHO/HRhzvx2xc78d/3O/Hf9yvxz/dMAf/37ELv+AxTL/ecIm/3O/Hf9zvx3/c78d/3O/HbFzvx0Yc78dAHS/GgBzvx0Ac78dGHO/HZJzvx3uc78d/3O/Hf9yvxz/cr8c/3O/HP9zvx3/c78d73O/HZJzvx0Yc78dAHS/GgAAAAAAAAAAAHO/HQBzvx0Hc78dSXO/Hahzvx3kc78d+3O/Hftzvx3kc78dqHO/HUlzvx0Hc78dAAAAAAAAAAAA4AcAAMADAACAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAIABAADAAwAA4AcAAA=="
                title="fiverr"
                alt="fiverr"
                  />
              </a>
            </Tooltip>
            </li>
            
            <li className="social-icons-freelancer">
            <Tooltip text="freelancer" placement="top">
              <a
                href="https://www.freelancer.com/u/bbijon"
                target="_blank"
                rel="noopener noreferrer"
              >
              <img
                className="img-fluid rounded-pill d-block"
                src="https://www.freelancer.com/favicon.ico"
                title="freelancer"
                alt="freelancer"
                  />
              </a>
            </Tooltip>
          </li>
            
          </ul>
          <button
            onClick={(e) => {
              setIsNavModalClose(!isNavModalClose);
            }}
            className={
              isNavModalClose ? "navbar-toggler" : "navbar-toggler show"
            }
            id="navbar-toggler"
            type="button"
          >
            <span />
            <span />
            <span />
          </button>
        </div>
      </nav>
      {/* Navbar End */}
    </header>
  );
};

export default Header;
